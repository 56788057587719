import { Inject, Injectable } from '@angular/core';

import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { has } from 'lodash';

@Injectable()
export class LoggedGuard  {
  constructor(
    private auth: MsalService,
    private router: Router,
    private storage: StorageService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {
  }
  async canActivate(): Promise<boolean> {
    try {
      const tokenResponse = await this.auth.handleRedirectObservable().toPromise();
      if (!tokenResponse) {
        const accounts = this.auth.instance.getAllAccounts();
        const token = this.storage.get(environment.authProvider.accessTokenName);
        if (accounts.length === 0) {
          await this.auth.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest).toPromise();
          return false;
        }
        if (!token) {
          await this.auth.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest).toPromise();
          return false;
        }
        this.router.navigate(['home']);
        return true;
      } else {
        const account: AccountInfo = this.auth.instance.getAllAccounts()[0];
        this.storage.set(environment.authProvider.accessTokenName, tokenResponse.accessToken);
        this.startRefreshTokenTimer(tokenResponse.accessToken);
        this.setAuthToken(account);
        this.router.navigate(['home']);
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  private setAuthToken(account: AccountInfo): void {
    const info: any = account.idTokenClaims;
    if (has(info, 'tid')) {
      this.storage.set(environment.authProvider.authToken, info.tid);
    }
  }
  refreshToken() {
    const account = {} as AccountInfo;
    this.auth.acquireTokenSilent(
      { scopes: environment.scopes, forceRefresh: true, account }
    )
      .toPromise().then(resp => {
        this.storage.set(environment.authProvider.accessTokenName, resp?.accessToken);
        this.startRefreshTokenTimer(resp?.accessToken);
      }).catch(() => this.auth.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest).toPromise())
  }
  // helper methods
  private refreshTokenTimeout: any;
  public startRefreshTokenTimer(jwtToken: any) {
    this.stopRefreshTokenTimer();
    const jwtTokenBase = JSON.parse(atob(jwtToken.split('.')[1]));
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtTokenBase.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }
  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
