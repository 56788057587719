import { EStorageType } from '@pichincha/typescript-sdk';
import { environment } from '../../environments/environment';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { Key } from './key.enum';
/**
 * Configuration for storage in browser
 */
export const ConfigStorage = { storageType: EStorageType.SESSION, secretKey: Key.STORAGE };

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const AuthApplication = new PublicClientApplication({
  auth: {
    clientId: environment.authProvider.clientId,
    authority: environment.authProvider.authority,
    redirectUri: environment.authProvider.redirectUri,
    navigateToLoginRequestUrl: false,
    knownAuthorities: [environment.authProvider.authority],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE
  }
});

export const GuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: environment.scopes
  }
}

const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set(environment.graphUri, ['user.read', 'mail.read']);

export const InterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap
}
