import { Component } from '@angular/core';

@Component({
  selector: 'app-empty',
  template: '<div></div>'
})
export class EmptyComponent {

  constructor() { }

}
