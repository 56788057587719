import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './componentes/login/login.component';
import { EmptyComponent } from './componentes/empty/empty.component';
import { LoggedGuard } from './guard/logged.guard';
import { RoutingDetail } from './config/router-mfa.config';
import { Guards, ListLayout } from './config/layouts';

const routerDetail = new RoutingDetail();
const routesList: Routes = routerDetail.routerList(ListLayout, Guards);

const routes = [
  {
    path: 'login',
    component: LoginComponent,
  } as Route,
  {
    path: '',
    component: EmptyComponent,
    canActivate: [LoggedGuard]
  } as Route,
  {
    path: 'state',
    component: EmptyComponent,
    canActivate: [LoggedGuard]
  } as Route,
  {
    path: 'code',
    redirectTo: '/',
  } as Route,
 ...routesList
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
