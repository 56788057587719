// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://desarrollo-portalpagos.pichincha.com/api",
  mainPath: 'home',
  microApps: "https://desarrollo-portalpagos.pichincha.com/assets/microapp-dev.json",
  loginUrl: "/",
  authProvider: {
    clientId: 'ed496950-83ca-4871-8d94-6b4e35018383',
    authority: 'https://login.microsoftonline.com/f5b0d682-1497-4db0-9019-660035554e72/',
    redirectUri: 'https://desarrollo-portalpagos.pichincha.com',
    redirectLogout: 'https://desarrollo-portalpagos.pichincha.com',
    accessTokenName: 'access_token',
    authToken: 'auth_token'
  },
  scopes: ['api://5f655567-2fe3-4f7d-8aef-017c2a387ab0/access_as_user User.Read'],
  graphUri: 'https://graph.microsoft.com/v1.0/me',
  translationFiles: 'https://desarrollo-portalpagos.pichincha.com/assets/locales/',
  urlOrigin: 'url_origin'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
