<div class="background-container wrapper" [ngClass]="{ general: allowLogin }">
  <div class="page-content">
    <div class="content m-auto element-content">
      <div class="icon m-auto">
        <a>
          <img src="assets/logo.svg" alt="logo" class="img-icon" />
        </a>
      </div>
      <div class="m-auto py-4">
        <pichincha-typography weight="normal" variant="captionText">
          Ingresa e inicia sesión con tu usuario y contraseña de red habitual.
        </pichincha-typography>
        <label class="Prelo-Medium text-center"></label>
      </div>
      <div class="m-auto cc" [ngStyle]="{'margin-left':'681px'}">
        <div *ngIf="!isLoggedIn()">
          <pichincha-button (clickbutton)="login()" id="save-button" color="primary">
            Ingresar
          </pichincha-button>
        </div>
        <div *ngIf="isLoggedIn()">
          <pichincha-button (clickbutton)="logout()" id="save-button" color="primary">
            Cerrar sesión
          </pichincha-button>
        </div>
      </div>
    </div>
  </div>
</div>
