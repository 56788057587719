import { AfterViewInit, Component, OnInit } from '@angular/core';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { LoggedGuard } from './guard/logged.guard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app-account-to-pay-angular';

  constructor(
    private loggedGuard: LoggedGuard,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const token = this.storageService.get('access_token');
    if (token) {
      setTimeout(() => {
        this.loggedGuard.startRefreshTokenTimer(token);
      }, 2000);
    }
  }
}
