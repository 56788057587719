import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  texto = 'Ingresar';
  allowLogin = true;
  isIframe = false;
  loginDisplay = false;
  title = 'app-account-to-pay-angular';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private malService: MsalService,
    private router: Router,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.malService.instance.handleRedirectPromise().then(
      res => {
        if (res != null && res.account != null) {
          this.malService.instance.setActiveAccount(res.account);
        } else if (this.malService.instance.getAllAccounts().length > 0) {
          this.goHome()
        }
      }
    );
  }

  goHome() {
    this.router.navigate(['home'])
  }

  isLoggedIn(): boolean {
    return this.malService.instance.getActiveAccount() != null;
  }
  login() {
    this.malService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
  }

  logout() {
    this.malService.logoutRedirect({
      postLogoutRedirectUri: environment.authProvider.redirectLogout
    });
    this.storageService.clear()
  }

}
