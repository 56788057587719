import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes } from '@angular/router';
import { LoginComponent } from './componentes/login/login.component';
import {
  LoaderFederationModule,
  LoaderConfigurationFederated,
} from "@pichincha/angular-sdk/module-federation";
import { environment } from 'src/environments/environment';
import { FullPageLayoutComponent } from './layouts/full-page-layout/full-page-layout.component';
import { UserProfileGuard } from './guard/user-profile.guard';
import { EmptyComponent } from './componentes/empty/empty.component';
import { LoggedGuard } from './guard/logged.guard';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: EmptyComponent,
    canActivate: [LoggedGuard]
  },
  {
    path: 'state',
    component: EmptyComponent,
    canActivate: [LoggedGuard]
  },
  {
    path: 'code',
    redirectTo: '/',
  },

];

const configuration: LoaderConfigurationFederated = {
  remoteConfigurationUrl: environment.microApps,
  extra: {
    useHash: true, preloadingStrategy: PreloadAllModules
  },
  layouts: [
    {
      component: FullPageLayoutComponent,
      name: "fullpage",
      onEnterRoute: [UserProfileGuard],
    },
  ],
};
@NgModule({
  imports: [LoaderFederationModule.forRoot(routes, configuration)],
  exports: [LoaderFederationModule],
})
export class AppRoutingModule { }
