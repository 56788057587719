import { UserProfileGuard } from "../guard/user-profile.guard";
import { FullPageLayoutComponent } from "../layouts/full-page-layout/full-page-layout.component";

export const ListLayout: any = {
    fullpage: FullPageLayoutComponent,
};

export const Guards: any = {
    logger: UserProfileGuard,
};
