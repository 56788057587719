import { Inject, Injectable } from '@angular/core';

import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { StorageService } from '@pichincha/angular-sdk/storage';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserProfileGuard  {


  constructor(
    private msal: MsalService,
    private storage: StorageService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) { }

  public async canActivate(): Promise<boolean> {
    this.setUrlOrigin();
    await this.msal.handleRedirectObservable().toPromise();
    const accounts: Array<AccountInfo> = this.msal.instance.getAllAccounts();
    if (accounts.length > 0) {
      return Promise.resolve(true);
    } else {
      await this.msal.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest).toPromise();
      return Promise.resolve(false);
    }
  }

  private setUrlOrigin(){
    const url = this.storage.get(environment.urlOrigin)
    if (!url){
      this.storage.set(environment.urlOrigin, window.location.href);
    }
  }
}
