import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { IPublicClientApplication } from '@azure/msal-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './componentes/login/login.component';
import { TopComponent } from './componentes/top-header/top.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { AuthApplication, GuardConfig, InterceptorConfig, ConfigStorage } from './config/initializer';
import { FullPageLayoutComponent } from './layouts/full-page-layout/full-page-layout.component';
import { HttpInterceptorRequest, HttpModule, setAuthorization, INTERCEPTOR_CONFIG_STORAGE } from '@pichincha/angular-sdk/http';
import { StorageModule } from '@pichincha/angular-sdk/storage';
import { EmptyComponent } from './componentes/empty/empty.component';
import { LoggedGuard } from './guard/logged.guard';
import { UserProfileGuard } from './guard/user-profile.guard';
import { InternationalizationModule } from './internationalization/internationalization.module';
import { environment } from 'src/environments/environment';


export function MSALInstanceFactory(): IPublicClientApplication {
  return AuthApplication;
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return GuardConfig;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return InterceptorConfig
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TopComponent,
    FooterComponent,
    FullPageLayoutComponent,
    EmptyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MsalModule,
    InternationalizationModule,
    StorageModule.forRoot(ConfigStorage),
    HttpModule.forRoot({
      api_url: environment.apiUrl
    }),

  ],
  providers: [
    {
      provide: HttpInterceptorRequest,
      useValue: setAuthorization
    },
    {
      provide: INTERCEPTOR_CONFIG_STORAGE,
      useValue: ConfigStorage
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UserProfileGuard,
    LoggedGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
})
export class AppModule { }
