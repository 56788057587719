import { GuardsMap, IRouteBase, LayoutsMap } from "./interfaces/routes.interface";
import { loadRemoteModule } from "@angular-architects/module-federation";
import { environment } from "src/environments/environment";

export class RoutingDetail {
    routesImport: IRouteBase[] = [];

    constructor() {
        this.loadRoutesFromFile()
    }

    loadRoutesFromFile() {
        const request = new XMLHttpRequest();
        try {
            request.open("GET", environment.microApps, false);
            request.send(null);
    
            if (request.status === 200) {
                this.routesImport = JSON.parse(request.responseText) as IRouteBase[];
            } else {
                console.error(`Failed to load microApps file. Status: ${request.status}, StatusText: ${request.statusText}`);
            }
        } catch (error) {
            console.error("Error occurred while loading or parsing the microApps file:", error);
        }
    }

    routerList(ListLayout: LayoutsMap, Guards: GuardsMap): any {
        const mapRoutes = this.routesImport.map((value) => {
            return this.mapRouteAngular(value, ListLayout, Guards);
        });
        return mapRoutes.flat();
    }

    mapRouteAngular(value: IRouteBase, ListLayout: any, Guards: any) {
        
        let { layout, guards, scope } = value;
        layout = layout ?? 'fullpage';
        guards = guards ?? [];
        return {
            path: scope,
            component: ListLayout[layout],
            children: [
                {
                    path: '',
                    loadChildren: () => this.routerModule(value),
                    canActivate: guards.map((guard) => {
                        return Guards[guard];
                    }),
                },
            ],
        };
    }

    async routerModule(value: IRouteBase) {
        try {
            const moduleResult = await loadRemoteModule({
                type: value.type,
                remoteEntry: value.urlResource || '',
                exposedModule: value.exposedModule || '',
            });
            return moduleResult.MFModule;
        }
        catch (error) {
            console.log(error);
            location.href = '/';
        }
    }
}
