import {
  Component,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { StorageService } from "@pichincha/angular-sdk/storage";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-top-header",
  templateUrl: "./top.component.html",
  styleUrls: ["./top.component.scss"],
})
export class TopComponent implements OnInit {

  ryrInlineStyles: object = {
    'color': '#09295D',
    'font-size': '14px',
    'line-height': '40px',
  };
  account: any;
  constructor(
    private malService: MsalService,
    private storageService: StorageService,
    private router: Router,
  ) { }


  ngOnInit(): void {
    this.setDataUser()
  }

  logout() {
    this.malService.logoutRedirect({
      postLogoutRedirectUri: environment.authProvider.redirectLogout
    });
    this.storageService.clear()
  }

  setDataUser() {
    const accounts = this.malService.instance.getAllAccounts()
    if (accounts.length !== 0) {
      const nameList = accounts[0].name?.split(' ').map(item => this.capitalizeFirstLetter(item));
      accounts[0].name = nameList?.join(' ');
      this.account = accounts[0]
    }

  }

  capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  goToHome(){
    this.storageService.remove(environment.urlOrigin);
    this.router.navigate(['/']);
  }

}
